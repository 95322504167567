import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Style from "./GiftCardsPersonalise.module.scss";
import SomeoneSpecial from "./SomeoneSpecial";

const GiftCardsPersonalise = ({
  formik,
  tab,
  setTab,
  select,
  setSelect,
  handleSelectChange,
  responseError,
}) => {
  const { t } = useTranslation(["common"]);

  return (
    <section className={Style.gift_cards_personalise}>
      <div className="container">
        <div className={`sm_container ${Style.wrapper}`}>
          <div className="text-md-center">
            <h1 className="h6">{t("PERSONALISE_GIFT_CARD")}</h1>
            <div className={`${Style.radio_wrap} pt-2`}>
              <div class={`form-check form-check-inline ${Style.form_check}`}>
                <input
                  class="form-check-input"
                  type="radio"
                  name="radioType"
                  id="inlineRadio1"
                  value="1"
                  defaultChecked={
                    formik.values.radioType === "1" ? true : false
                  }
                  onChange={(event) => handleSelectChange(event)}
                  onClick={() => {
                    setTab("1");

                    formik.setFieldValue("radioType", "1");
                  }}
                />
                <label class="form-check-label" for="inlineRadio1">
                  {t("Myself")}
                </label>
              </div>
              <div class={`form-check form-check-inline ${Style.form_check}`}>
                <input
                  class="form-check-input"
                  type="radio"
                  name="radioType"
                  id="inlineRadio2"
                  value="2"
                  defaultChecked={formik.values.radioType === "someone"}
                  onChange={(event) => handleSelectChange(event)}
                  onClick={() => {
                    setTab("2");
                    formik.setFieldValue("radioType", "someone");
                  }}
                />
                <label class="form-check-label" for="inlineRadio2">
                  {t("SomeOne")}
                </label>
              </div>
            </div>
            {tab === "1" ? (
              <>
                <div className="row justify-content-center mt-3">
                  <div className="col-md-6">
                    <Form.Group className="form-group text-start">
                      <Form.Label>{t("Name")}</Form.Label>
                      <Form.Control
                        type="text"
                        id="senderName"
                        placeholder={t("Full_Name")}
                        onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        value={formik.values.senderName}
                        name="senderName"
                      />
                    </Form.Group>
                    {formik.touched.senderName && formik.errors.senderName ? (
                      <div className="formikError">
                        {formik.errors.senderName}
                      </div>
                    ) : null}
                    <Form.Group className="form-group text-start">
                      <Form.Label>{t("Email")}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={t("E_mail")}
                        id="senderEmail"
                        name="senderEmail"
                        onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        value={formik.values.senderEmail}
                      />
                    </Form.Group>
                    {formik.touched.senderEmail && formik.errors.senderEmail ? (
                      <div className="formikError">
                        {formik.errors.senderEmail}
                      </div>
                    ) : null}
                    <Form.Group className="form-group text-start ">
                      <Form.Label>{t("Mobile_Number_Sender")}</Form.Label>
                      <Form.Control
                        type="text"
                        id="senderMobile"
                        placeholder={t("Mobile_Number")}
                        onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        value={formik.values.senderMobile}
                        name="senderMobile"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Group>
                    {formik.touched.senderMobile &&
                    formik.errors.senderMobile ? (
                      <div className="formikError">
                        {formik.errors.senderMobile}
                      </div>
                    ) : null}
                    {responseError && (
                      <p className={Style.submit_error}>{t("mobile_error")}</p>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <SomeoneSpecial
                tab={tab}
                setTab={setTab}
                formik={formik}
                responseError={responseError}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GiftCardsPersonalise;
