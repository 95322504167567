import React, { useEffect } from "react";
import Style from "./CheckoutPayment.module.scss";
import { FloatingLabel, Form, Button } from "react-bootstrap";
import useCardData from "../../../logic/useCardData";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCancelOrder } from "../../../store/slices/orderDetailsSlice";
import { useDispatch } from "react-redux";
import useApplePay from "../../../logic/useApplePay";
import { CreditCard } from "react-feather";

const CheckoutPayment = ({ data, paymentError, iFrameNull, setIFrameNull }) => {
  const { slug, lang: langSlug } = useParams();
  let langString = langSlug;
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);

  const INFO_MESSAGE = t("INFO_MESSAGE");
  const {
    pay,
    loadingShimmer,
    setLoadingShimmer,
    cardClick,
    setCardClick,
    applePayClick,
    setApplePayClick,
  } = useCardData(data?.session_id);

  useEffect(() => {}, [paymentError]);

  useEffect(() => {
    if (iFrameNull == true) {
      navigate(`/order-summary/${data?.id}/${langString}`, {
        state: { data },
      });
    }
  }, [iFrameNull]);

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  const cancelButton = async () => {
    const resultAction = await dispatch(getCancelOrder(slug));

    if (getCancelOrder.fulfilled.match(resultAction)) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  };

  const getDropList = () => {
    const year = new Date().getFullYear();
    return Array.from(new Array(9), (v, i) => (
      <option key={i} value={year + i}>
        {year + i}
      </option>
    ));
  };

  useEffect(() => {
    if (iFrameNull == true) {
      setLoadingShimmer(false);
    }
    setTimeout(() => {
      setIFrameNull(false);
    }, 2500);
  }, [iFrameNull]);

  // const { handleOnApplePayButtonClick, errorMessage } = useApplePay({
  //   ...data,
  // });
  // useEffect(() => {
  //   if (applePayClick) {
  //     try {
  //       let button = document.getElementById("pay-button");
  //       button.addEventListener("click", () => {
  //         // Add onClick Event Handler for apple-pay-button element
  //         handleOnApplePayButtonClick();
  //       });
  //     } catch (error) {
  //       console.log("Apple Pay Button onClick event Error: ", error);
  //     }
  //   }
  // }, [applePayClick]);

  // const REACT_APP_IP_SET = process.env.REACT_APP_IP_SET;

  // console.log(REACT_APP_IP_SET, "IP SET==============");

  return (
    <div className={Style.payment_detail}>
      <div className={`h5 ${Style.payment_detail_title}`}>{t("choose_type")}</div>
      <div className={`${Style.field_wrapper} ${Style.credit_card}`}>
        <div className={`${Style.form_label_wrapper}`}>
          <Form.Check
            type="radio"
            name="type"
            id="card"
            defaultChecked={true}
            label={t("cardType")}
            onClick={() => {
              setCardClick(true);
              setApplePayClick(false);
            }}
          />
          {/* <CreditCard /> */}
        </div>
      </div>

      <div className={`row ${Style.custom_row}`} style={{ display: !cardClick && "none" }}>
        <div className="col-12">
          <FloatingLabel
            controlId="cardNumber"
            label={t("Card_Number")}
            className={Style.form_group}
          >
            <Form.Control id="card-number" readOnly className={Style.form_control} />
          </FloatingLabel>
        </div>
        <div className="col-12">
          <FloatingLabel
            controlId="cardHolder"
            label={t("Card_Holder_Name")}
            className={Style.form_group}
          >
            <Form.Control
              type="text"
              id="cardholder-name"
              readOnly
              className={Style.form_control}
            />
          </FloatingLabel>
        </div>
        <div className="col-6">
          <FloatingLabel controlId="Month" label={t("Month")} className={Style.form_group}>
            <Form.Select
              aria-label="Month"
              className={Style.form_control}
              id="expiry-month"
              readOnly
            >
              <option value="1">Jan</option>
              <option value="2">Feb</option>
              <option value="3">Mar</option>
              <option value="4">Apr</option>
              <option value="5">May</option>
              <option value="6">Jun</option>
              <option value="7">Jul</option>
              <option value="8">Aug</option>
              <option value="9">Sep</option>
              <option value="10">Oct</option>
              <option value="11">Nov</option>
              <option value="12">Dec</option>
            </Form.Select>
          </FloatingLabel>
        </div>
        <div className="col-6">
          <FloatingLabel controlId="Year" label={t("Year")} className={Style.form_group}>
            <Form.Select aria-label="Year" className={Style.form_control} id="expiry-year" readOnly>
              {getDropList()}
            </Form.Select>
          </FloatingLabel>
        </div>
        <div className="col-6">
          <FloatingLabel controlId="Security" label={t("Security")} className={Style.form_group}>
            <Form.Control
              type="password"
              className={Style.form_control}
              id="security-code"
              readOnly
              maxLength="4"
            />
          </FloatingLabel>
        </div>

        <div className="col-12">
          <div className={Style.amount_summary}>
            {t("Total_amount")} :
            <span>
              {" "}
              <sup>{t("DHS")}</sup>
              {parseInt(data.amount)}{" "}
            </span>
          </div>
        </div>

        <div className="col-6">
          <button
            // variant="tertiary"
            type="button"
            className={`${Style.payment_btn} custom_btn custom_btn-tertiary`}
            onClick={cancelButton}
          >
            {t("CANCEL")}
          </button>
        </div>

        <div className="col-6">
          <button
            // variant="primary"
            type="button"
            className={
              loadingShimmer && !iFrameNull
                ? ` ${Style.payment_btn} loading custom_btn custom_btn-primary`
                : `${Style.payment_btn} custom_btn custom_btn-primary`
            }
            id="payButton"
            onClick={() => {
              pay("card");
              setLoadingShimmer(true);
            }}
          >
            {t("PAY")}
          </button>
        </div>

        <small className={Style.error}>{paymentError && <p>{paymentError}</p>}</small>
      </div>

      {/* <div className={`${Style.field_wrapper} ${Style.apple_pay}`}>
        <Form.Check
          type="radio"
          name="type"
          id="set_date"
          label={t("Applypay")}
          onClick={() => {
            setApplePayClick(true);
            setCardClick(false);
          }}
        />
      </div>
      {applePayClick && REACT_APP_IP_SET ? (
        <apple-pay-button id="pay-button" buttonstyle="black" type="plain" locale="en-US" />
      ) : (
        ""
      )} */}
      {/* {errorMessage && applePayClick && (
        <div>
          <span>{errorMessage}</span>
        </div>
      )}
      {!errorMessage && applePayClick && (
        <div>
          <span>{INFO_MESSAGE}</span>
        </div>
      )} */}
    </div>
  );
};

export default CheckoutPayment;
