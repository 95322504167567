import Style from "./Transaction.module.scss";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { Link, useNavigate, useParams, UNSAFE_NavigationContext } from "react-router-dom";
import { useStorePurchase } from "../../logic/useStorePurchase";
import TransactionFailed from "./transaction-failed.svg";
import { useDispatch } from "react-redux";
import moment from "moment";

const Transaction = () => {
  const navigation = useContext(UNSAFE_NavigationContext).navigator;

  useEffect(() => {
    let unlisten = navigation.listen((locationListener) => {
      if (locationListener.action === "POP") {
        //do your stuff on back button click
        navigate("/");
        window.location.reload();
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  const { orderId, langTrans: langSlug } = useParams();
  const { fetchCardDetails, details } = useStorePurchase();

  const navigate = useNavigate();

  const { t } = useTranslation(["common"]);

  useEffect(() => {
    fetchCardDetails(orderId);
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("language_type");
    if (lang === "ar") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "rtl");
    } else if (lang === "en") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "ltr");
    } else {
      localStorage.setItem("language_type", "en");
    }
  }, []);

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  const handleHide = () => {
    const lang = localStorage.getItem("language_type");
    if (lang === "ar") {
      navigate("/ar");
      window.location.reload();
    } else {
      navigate("/en");
      window.location.reload();
    }
  };

  return (
    <div className={"container"}>
      {details?.payment_status == 1 ? (
        <>
          <div className={Style.transaction_wrapper}>
            <h2 className={Style.transaction_heading}> {t("order_summary")}</h2>
            <div className={Style.transaction_content}>
              <h2 className={Style.transaction_content_title}> {t("Order_placed_successfully")}</h2>
              <div className={Style.order_details}>
                <div>
                  <p>
                    {" "}
                    {t("Your_order_number")} #{details?.transaction_result?.order?.id}
                  </p>
                  <p>
                    {t("Order_total")}:
                    <strong>
                      <sup>{t("DHS")}</sup> {details?.amount}
                    </strong>
                  </p>
                </div>
                <div>
                  <div className={Style.card_status_items}>
                    {t("Purpose_of_buying")} :
                    <span>{details?.purchase_mode == 1 ? t("Bought") : t("Gifted")}</span>
                  </div>
                </div>
              </div>

              <div class={Style.fig_wrapper}>
                <figure class={Style.fig_img_wrapper}>
                  <img src={details?.gift_card?.gift_card_full_url} alt="Transaction" />
                </figure>
              </div>
              <div className={Style.card_status}>
                <div className={Style.card_status_block}>
                  <div className={Style.card_status_items}>
                    {t(!details?.sender_name ? "Sender_Name" : "Recipient_Name2")}
                    :&nbsp;
                    <span>{details?.name}</span>
                  </div>
                  <div className={Style.card_status_items}>
                    {t(!details?.sender_name ? "Sender_Email" : "Recipient_mail2")}
                    :&nbsp;
                    <span>{details?.email}</span>
                  </div>
                  <div className={Style.card_status_items}>
                    {t(!details?.sender_name ? "Sender_Mobile_Number_Sender" : "Recipient_Number2")}
                    :&nbsp;<span>{details?.mobile}</span>
                  </div>
                </div>
                <div className={Style.card_status_block}>
                  {details?.sender_name && (
                    <div className={Style.card_status_items}>
                      {t("Senders_Name_check")}:&nbsp;
                      <span>{details?.sender_name}</span>
                    </div>
                  )}
                  <div className={Style.card_status_items}>
                    {t("Arrive_ASAP")}:&nbsp;
                    <span>
                      {new Date().toDateString() === new Date(details?.date).toDateString()
                        ? t("Now")
                        : moment(details?.date, "yyyy/MM/DD").format("DD/MM/yyyy")}
                    </span>
                  </div>
                </div>
              </div>

              <div className={Style.btn_wrapper}>
                <Link
                  className={`custom_btn custom_btn-primary ${Style.home_button}`}
                  to="/"
                  onClick={handleHide}
                >
                  {t("Home")}
                </Link>
              </div>
            </div>
          </div>
        </>
      ) : details?.payment_status == 2 ? (
        <>
          <div className={Style.contentWrapper}>
            <div class={Style.fig_wrapper}>
              <figure class={Style.fig_img_wrapper}>
                <img src={TransactionFailed} alt="Transaction Failed" />
              </figure>
            </div>
            <div className={Style.message_title}>{t("order_failed")}</div>
            <div className={`${Style.message_status} ${Style.error}`}>{t("Status_error_msg")}</div>
            <button onClick={handleHide} className={"custom_btn custom_btn-primary"}>
              {t("Home")}
            </button>
          </div>
        </>
      ) : (
        <>{t("Loading")}....</>
      )}
    </div>
  );
};

export default Transaction;
